import { Inject, Service } from "typedi";
import { DomMethods } from "../../../application/core/shared/dom-methods/DomMethods";
import { IGooglePayPaymentRequest } from "../../../integrations/google-pay/models/IGooglePayPaymentRequest";
import { IGooglePaySessionPaymentsClient } from "../../../integrations/google-pay/models/IGooglePaySessionPaymentsClient";
import { IConfig } from "../../../shared/model/config/IConfig";
import { type IInternalsMonitor } from "../../../application/core/services/monitoring/IInternalsMonitor";
import { IPaymentData } from "../../../integrations/google-pay/models/IPaymentData";
import { IGooglePayDisplayItem } from "../../../integrations/google-pay/models/IGooglePayDisplayItem";
import { IGooglePaymentDataCallBacks } from "../../../integrations/google-pay/models/IGooglePaymentDataCallBacks";
import { GooglePayConfigMapper } from "./GooglePayConfigMapper";
import { GooglePayPaymentService } from "./GooglePayPaymentService";

export enum GooglePayButtonActionResult {
  SUCCESS = "SUCCESS",
  CANCELED = "CANCELED",
  ERROR = "ERROR",
}
@Service()
export class GooglePayButtonAction {
  private config: IConfig;
  constructor(
    private googlePayPaymentService: GooglePayPaymentService,
    @Inject("IInternalsMonitor") protected internalMonitor: IInternalsMonitor,
  ) {}

  async execute(
    config: IConfig,
    googlePaySdk: IGooglePaySessionPaymentsClient,
  ): Promise<GooglePayButtonActionResult> {
    this.config = config;

    const googleConfigMapper = new GooglePayConfigMapper();

    const paymentDataRequest: IGooglePayPaymentRequest =
      googleConfigMapper.createPaymentDataRequest(config);

    this.recordMerchantsMisconfigurationIssue(
      config.googlePay.paymentRequest.paymentDataCallbacks,
      config.googlePay.paymentRequest.transactionInfo.displayItems,
    );

    try {
      const paymentData = await googlePaySdk.loadPaymentData({
        ...paymentDataRequest,
        transactionInfo: { ...paymentDataRequest.transactionInfo },
      });

      this.onPaymentAuthorized(paymentData);
      return GooglePayButtonActionResult.SUCCESS;
    } catch (error: unknown) {
      if (typeof error === "object" && error !== null) {
        if ("statusCode" in error && error?.statusCode === "CANCELED") {
          this.onPaymentCancel();
          return GooglePayButtonActionResult.CANCELED;
        } else {
          this.onPaymentError();
          return GooglePayButtonActionResult.ERROR;
        }
      } else {
        this.onPaymentError();
        return GooglePayButtonActionResult.ERROR;
      }
    }
  }

  private recordMerchantsMisconfigurationIssue(
    paymentDataCallbacks: IGooglePaymentDataCallBacks,
    displayItems: IGooglePayDisplayItem[],
  ) {
    /**
     * We've found that if displayItems is set but no callbacks are, then
     * we run into a developer error. To avoid this, we add the check.
     */
    const canIncludeDisplayItems =
      Object.keys(paymentDataCallbacks ?? {}).length > 0;

    if (!canIncludeDisplayItems && displayItems?.length > 0) {
      this.internalMonitor.recordIssue(
        new Error(
          "The merchant has misconfigured GooglePay. Display items set without callbacks.",
        ),
        displayItems,
      );
    }
  }

  private onPaymentAuthorized(paymentData: IPaymentData): void {
    const formData = DomMethods.parseForm(this.config.formId);
    return this.googlePayPaymentService.processPayment(formData, paymentData);
  }

  private onPaymentCancel(): void {
    const formData = DomMethods.parseForm(this.config.formId);
    return this.googlePayPaymentService.cancelPayment(formData);
  }

  private onPaymentError(): void {
    const formData = DomMethods.parseForm(this.config.formId);
    return this.googlePayPaymentService.errorPayment(formData);
  }
}
